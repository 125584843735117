import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../styles/Slider.css';
import slideImg1 from '../img/slider_image_1.png';
import slideImg2 from '../img/slider_image_2.png';
import slideImg3 from '../img/slider_image_3.png';

function clampBuilder(minWidthPx, maxWidthPx, minFontSize, maxFontSize) {
  const root = document.querySelector("html");
  const pixelsPerRem = Number(getComputedStyle(root).fontSize.slice(0, -2));

  const minWidth = minWidthPx / pixelsPerRem;
  const maxWidth = maxWidthPx / pixelsPerRem;

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minFontSize;

  return `clamp(${minFontSize}rem, ${yAxisIntersection}rem + ${slope * 100}vw, ${maxFontSize}rem)`;
}

function Slider() {
  const hFontSize = clampBuilder(360, 1280, 1, 3.5);
  const pFontSize = clampBuilder(360, 1280, 1.2, 2);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={30}
      slidesPerView={1}
      navigation
      autoplay={{
        delay: 2500,
        disableOnInteraction: true,
      }}
      pagination={{ clickable: true }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
      }}
    >
      <SwiperSlide>
        <div className='container slide-container slide1'>
          <div className="content-wrapper">
            <div className="text-wrapper">
              <div className="lead-in">
                <h2 style={{ fontSize: hFontSize }}>Fast Conversion From USD to USDT & USDC</h2>
                <p style={{ fontSize: pFontSize }}>Convert your USD to USDT or USDC instantly. Quick, easy, and secure, ensuring your transactions are smooth and hassle-free.</p>
              </div>
              <button className="cta" onClick={() => scrollToSection('support')}>Get Started</button>
            </div>
            <div className="image-wrapper">
              <img src={slideImg1} alt="Slide 2" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='container slide-container slide2'>
          <div className="content-wrapper">
            <div className="text-wrapper">
              <div className="lead-in">
                <h2 style={{ fontSize: hFontSize }}>Easy Cross Border Payments At Your Fingertips.</h2>
                <p style={{ fontSize: pFontSize }}>Send money across borders effortlessly with our platform. Fast, reliable, and simple, making international payments a breeze!</p>
              </div>
              <button className="cta" onClick={() => scrollToSection('support')}>Get Started</button>
            </div>
            <div className="image-wrapper">
              <img src={slideImg2} alt="Slide 2" />
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='container slide-container slide3'>
          <div className="content-wrapper">
            <div className="text-wrapper">
              <div className="lead-in">
                <h2 style={{ fontSize: hFontSize }}>
                  <span>Swift.</span>
                  <span>Secure.</span>
                  <span>Seamless.</span>
                </h2>
                <p style={{ fontSize: pFontSize }}>Experience quick, safe, and seamless international payments. Our platform ensures your money transfers are efficient and stress-free.</p>
              </div>
              <button className="cta" onClick={() => scrollToSection('support')}>Get Started</button>
            </div>
            <div className="image-wrapper">
              <img src={slideImg3} alt="Slide 2" />
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default Slider;
