import React, { useState } from 'react';
import '../styles/Navbar.css';

import logo from '../img/logo.png';

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo"><img src={logo} alt="PAP Logo" className="src" /></div>
      <button className="navbar-toggler" onClick={toggleNavbar}>
        ☰
      </button>
      <div className={`nav-links ${isOpen ? 'open' : ''}`}>
        <ul>
          <button className='nav-btns' onClick={() => scrollToSection('top')}><li>Home</li></button>
          <button className='nav-btns' onClick={() => scrollToSection('about')}><li>About</li></button>
          <button className='nav-btns' onClick={() => scrollToSection('top')}><li>Solutions</li></button>
          <button className='nav-btns' onClick={() => scrollToSection('support')}><li>Support</li></button>
          <button className='nav-btns' onClick={() => scrollToSection('faq')}><li>FAQ</li></button>
        </ul>
        <button className="get-started" onClick={() => scrollToSection('support')}>Get Started</button>
      </div>
    </nav>
  );
}

export default NavBar;
