import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

import '../styles/Contact.css';
import happyCustomer from '../img/happyCustomer.png'

// https://docs.google.com/forms/u/0/d/e/1FAIpQLScAuxovrTe01YRTMcEUleBaykeyXp4THmjpdL9raIWRhPqfMw/formResponse

function ContactSection() {
  const [state, handleSubmit] = useForm("mwpezvjz");
  return (
    <div id='support' className="contact-section container">
      <div className="image-container"><img src={happyCustomer} alt="Contact" /></div>
      <div className="contact-form">
        <h2>
          <span>Get Exclusive Access</span> To Currency Conversion Service!
        </h2>
        {state.succeeded ? (<p>Success</p>) : (
          <form className='form' onSubmit={handleSubmit}>
            <div className="email">
              <label htmlFor="email">Email</label>
              <input id='email' type="text" name="email" placeholder="Enter your Email" />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </div>
            <div className="phone">
              <label htmlFor="phone">Phone Number</label>
              <input id='phone' type="text" name="phone" placeholder='Enter your phone number' />
              <ValidationError
                prefix="Phone"
                field="phone"
                errors={state.errors}
              />
            </div>
            <input type="submit" value="Access Pap Easily" />
          </form>
        )
        }
      </div>
    </div>
  );
}

export default ContactSection;
