import React from 'react';
import '../styles/Download.css';

function DownloadSection() {
  return (
    <div id='apps' className="download-section container">
      <h2>Download Pap now and move your money safely and quickly between Africa and Asia. Begin your journey today.</h2>
      <div className="download-buttons">
        <button className="apple-store"></button>
        <button className="play-store"></button>
      </div>
    </div>
  );
}

export default DownloadSection;
