import React from 'react';
import '../styles/About.css';

import iphonemockup from '../img/iphonemockup.png'
import money from '../img/money.png'
import thunderbolt from '../img/thunderbolt.png'
import shield from '../img/shield.png'


function AboutSection() {
  return (
    <div id='about' className="about-section container">
      <div className="about-pap">
        <h2><span>Fast and secure cross-border</span> transactions made easy</h2>
        <div className="content">
          <div className="mockup-image">
            <img src={iphonemockup} alt="mockup" />
          </div>
          <div className='feature-list'>
            <div className='feature-item'>
              <div className='icon-container'>
                <img src={shield} alt="Shield" />
              </div>
              <div className='feature-text'>
                <h3>Safe and Secure Funds </h3>
                <p>Move your money safely and quickly between Africa and Asia.</p>
              </div>
            </div>
            <div className='feature-item'>
              <div className='icon-container'>
                <img src={thunderbolt} alt="Thunder Bolt" />
              </div>
              <div className='feature-text'>
                <h3>Speedy Transactions </h3>
                <p>Transact across borders with top-tier security and unmatched speed.</p>
              </div>
            </div>
            <div className='feature-item'>
              <div className='icon-container'>
                <img src={money} alt="Money" />
              </div>
              <div className='feature-text'>
                <h3>Elastic Liquidity</h3>
                <p>No frustrating queues or wait lists, your funds are available to you at all times.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
