import React, { useState } from 'react';
import '../styles/FAQ.css';

function FAQSection() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const faqs = [
    { question: 'What services or products do you offer?', answer: 'We offer On-Ramp conversion from USD to USDT & USDC at lightening speed. We make seamless Payments to your supplier' },
    { question: 'How can I contact customer support?', answer: 'You can reach us at Support@papdigital.org' },
    { question: 'What are your business hours?', answer: 'You can reach out to us from 9am to 6pm GMT+1' },
    { question: 'Do I need to create an account to use Pap Digital?', answer: 'Yes you do. Once you sign up and submit your kyc/kyb documents, you would be able to access our features upon verification' },
  ];

  return (
    <div id='faq' className="faq-section container">
      <h2>Frequently Asked Questions</h2>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span>{activeIndex === index ? '-' : '+'}</span>
          </div>
          {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
        </div>
      ))}
    </div>
  );
}

export default FAQSection;
