import React from 'react';
import '../styles/VideoSection.css';

function VideoSection() {
    return (
        <div className="video-section">
            <div className="about-video">
                <iframe
                    width="80%"
                    src="https://www.youtube.com/embed/F87vlrz-KlM"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="About Video"
                ></iframe>
            </div>
        </div>
    );
}

export default VideoSection;
