import React from 'react';
import './App.css';
import NavBar from './components/Navbar';
import Slider from './components/Slider';
import ContactSection from './components/Contact';
import AboutSection from './components/About';
import FAQSection from './components/FAQ';
import VideoSection from './components/VideoSection';
import DownloadSection from './components/Download';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Slider />
      <ContactSection />
      <AboutSection />
      <VideoSection />
      <FAQSection />
      <DownloadSection />
      <Footer />
    </div>
  );
}

export default App;
