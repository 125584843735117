import React from 'react';
import '../styles/Footer.css';

import logo from '../img/logo.png';
import facebookIcon from '../img/uim_facebook.png';
import twitterIcon from '../img/uim_twitter.png';
import linkedinIcon from '../img/uim_linkedin.png';

function Footer() {
  return (
    <footer className="footer">
      <div className="top">
        <div className="info">
          <div className="footer-description footer-card">
            <div className="heading"><img src={logo} alt="PAP Logo" className="src" /></div>
            <div className="body">With PAP you can make fast, seamless & transparent Payment to suppliers, and also Enjoy Best in the Market Exchange Rate with PAP.</div>
          </div>
          <div className="footer-sitemap footer-card">
            <div className="heading"><h4>Sitemaps</h4></div>
            <div className="body">
              <ul>
                <li>Home</li>
                <li>FAQ</li>
                <li>About Us</li>
              </ul>
            </div>
          </div>
          <div className="footer-newsletter footer-card">
            <div className="heading"><h4>Newsletter</h4></div>
            <div className="body">
              <input type="email" placeholder="Enter your Email" />
              <input type="submit" value="Sign Up" />
            </div>
          </div>
          <div className="footer-social footer-card">
            <div className="heading"><h4>Follow us</h4></div>
            <div className="body">
              <ul>
                <li><img src={facebookIcon} alt="Facebook" /></li>
                <li><img src={twitterIcon} alt="Twitter" /></li>
                <li><img src={linkedinIcon} alt="LinkedIn" /></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">© copyright 2024 Pap limited all rights reserved.</div>
      </div>
      <div className="base"></div>
    </footer>
  );
}

export default Footer;
